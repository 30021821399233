import { createAsyncThunk } from '@reduxjs/toolkit';

import assign from 'lodash/fp/assign';
import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import get from 'lodash/fp/get';
import keys from 'lodash/fp/keys';
import map from 'lodash/fp/map';
import orderBy from 'lodash/fp/orderBy';
import reduce from 'lodash/fp/reduce';

import { type ISOStringDate, type Nullable, type Pages } from 'types/common';

import questionApiSlugs from 'Apps/Customers/Components/utils/SurveyUpdates/questionSetApiSlugs';

import initApiClient from 'services/ApiClient';

import getEnv from 'utils/getEnv';
import getJson from 'utils/getJson';
import logSentryError from 'utils/sentry';

import type { Cart } from './types/cart-types';
import type { Customer } from './types/customer-types';
import type { FormulasPreview } from './types/formulasPreview-types';
import type { QuestionSetQuestion } from './types/questionSet-types';
import type {
  Customers,
  Fragrances,
  ImageDocs,
  Orders,
  Profiles,
  QuestionSetCategories,
} from './types/slice-types';
import type { CouponsDictionary } from '../coupons/types';
import type { Subscriptions } from '../subscriptions/types';
import type { Surveys } from '../surveys/types';
import transformCoupons from './transformCoupons';

export const fetchCustomers = createAsyncThunk<{ customers: Customers; pages: Pages }, string>(
  'customer/fetchCustomers',
  async (url, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const query = await APIClient.get(url);
      const pages: Pages = get('pages', query);
      const customers = await query.json();
      return { customers, pages };
    } catch (err) {
      logSentryError('[Customer Actions] fetch customers', err);
      throw err;
    }
  }
);

export const fetchCustomer = createAsyncThunk<Customer, string>(
  'customer/fetchCustomer',
  async (pubkey, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const query = await APIClient.get(`/v1/backoffice/customers/${pubkey}`);
      const customer = await query.json();
      return customer;
    } catch (err) {
      logSentryError('[Customer Actions] fetch customer', err);
      throw err;
    }
  }
);

export const fetchCustomerOrders = createAsyncThunk<{ orders: Orders; pages: Pages }, string>(
  'customer/fetchCustomerOrders',
  async (pubkey, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const query = await APIClient.get(`/v1/backoffice/customers/${pubkey}/orders/`);
      const pages: Pages = get('pages', query);
      const orders = await query.json();
      return { orders, pages };
    } catch (err) {
      logSentryError('[Customer Actions] fetch customer orders', err);
      throw err;
    }
  }
);

type Params = { pubkey: string; isCredit: Nullable<string | boolean> };

type DataType = {
  credits: CouponsDictionary;
  promos: CouponsDictionary;
  creditsPages: Pages;
  promosPages: Pages;
};

export const fetchCustomerCoupons = createAsyncThunk<Partial<DataType>, Params>(
  'customer/fetchCustomerCoupons',
  async (params, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const { pubkey, isCredit } = params;
      const query = await APIClient.get(
        `/v1/backoffice/customers/${pubkey}/coupons/?is_credit=${isCredit}`
      );

      const pages: Pages = get('pages', query);
      const couponsResponse = await query.json();

      const mode = isCredit ? 'credits' : 'promos';

      const data: Partial<DataType> = {
        [mode]: transformCoupons(orderBy(['coupon.created_at'], ['desc'], couponsResponse)),
        [`${mode}Pages`]: pages,
      };

      const coupons: Partial<DataType> = {
        credits: data?.credits,
        creditsPages: data?.creditsPages,
        promos: data?.promos,
        promosPages: data?.promosPages,
      };

      return coupons;
    } catch (err) {
      logSentryError('[Customer Actions] fetch customer orders', err);
      throw err;
    }
  }
);

export const fetchCustomerSubscriptions = createAsyncThunk<Subscriptions, string>(
  'customer/fetchCustomerSubscriptions',
  async (pubkey, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const query = await APIClient.get(`/v1/backoffice/customers/${pubkey}/subscriptions/`);
      const allSubscriptions = await query.json();

      const subscriptionPromises = await Promise.all(
        map(
          allSubscription =>
            APIClient.get(`/v1/backoffice/subscriptions/${allSubscription.pubkey}/`),
          allSubscriptions
        )
      );
      const subscriptionsDetail = await getJson(subscriptionPromises);

      const subscriptions = map(subscriptionDetail => {
        const subscription = find({ pubkey: subscriptionDetail.pubkey }, allSubscriptions);
        return { ...subscriptionDetail, ...subscription };
      }, subscriptionsDetail);

      return subscriptions;
    } catch (err) {
      logSentryError('[Customer Actions] fetch customer subscriptions', err);
      throw err;
    }
  }
);

export const fetchCustomerProfiles = createAsyncThunk<Profiles, string>(
  'customer/fetchCustomerProfiles',
  async (pubkey, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);

      const [questionsPromise, profilePromise] = await Promise.all([
        APIClient.get(`/v1/backoffice/profile/questions`),
        APIClient.get(`/v1/backoffice/customers/${pubkey}/profile`),
      ]);
      const questions = await questionsPromise.json();
      const profile = await profilePromise.json();

      const profiles = map(
        category =>
          reduce(
            (acc, value) =>
              assign(acc, {
                category,
                created_at: profile.created_at,
                updated_at: profile.updated_at,
                [value]: profile[value],
              }),
            {},
            questions[category]
          ),
        keys(questions)
      );

      return { profiles, hairProfilePubkey: profile.pubkey };
    } catch (err) {
      logSentryError('[Customer Actions] fetch customer profiles', err);
      throw err;
    }
  }
);

export const fetchCustomerFragrances = createAsyncThunk<Fragrances, string>(
  'customer/fetchCustomerFragrances',
  async (pubkey, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);

      const fragrancesResponse = await APIClient.get(
        `/v1/backoffice/hair_profile/${pubkey}/fragrances/`,
        {
          category: 'haircare',
        }
      );
      const fragrances = await fragrancesResponse.json();

      return fragrances.fragrances;
    } catch (err) {
      logSentryError('[Customer Actions] fetch customer profiles', err);
      throw err;
    }
  }
);

export const fetchQuestionSets = createAsyncThunk<QuestionSetCategories, string>(
  'customer/fetchQuestionSets',
  async (_, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const haircareQuestionSetPromiseReponse = await APIClient.get(
        `/v1/backoffice/question_sets/haircare/category/`
      );
      const haircareQuestionSet = await haircareQuestionSetPromiseReponse.json();

      const enhancedQuestionSet = map(
        question => ({ ...question, apiSlugs: questionApiSlugs[question.slug] }),
        haircareQuestionSet.questions
      );

      const categories: QuestionSetCategories = reduce(
        (acc, question: QuestionSetQuestion) => {
          if (question.section === 'hair-scalp') {
            acc.hairScalp.push(question);
            return acc;
          }
          if (question.section === 'my-preferences') {
            acc.myPreferences.push(question);
            return acc;
          }
          acc[question.section].push(question);
          return acc;
        },
        {
          hairScalp: [],
          treatments: [],
          myPreferences: [],
          lifestyle: [],
        },
        enhancedQuestionSet
      );

      return categories;
    } catch (err) {
      logSentryError('[Customer Actions] fetch question sets', err);
      throw err;
    }
  }
);

export const fetchCustomerSurveys = createAsyncThunk<{ surveys: Surveys; pages: Pages }, string>(
  'customer/fetchCustomerSurveys',
  async (pubkey, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const query = await APIClient.get(`/v1/backoffice/customers/${pubkey}/surveys/`);
      const pages: Pages = get('pages', query);
      const surveys = await query.json();
      return { surveys, pages };
    } catch (err) {
      logSentryError('[Customer Actions] fetch customer surveys', err);
      throw err;
    }
  }
);

export const fetchCustomerCart = createAsyncThunk<Cart, { pubkey: string; softCompute: boolean }>(
  'customer/fetchCustomerCart',
  async ({ pubkey, softCompute }, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const query = await APIClient.get(
        `/v1/backoffice/customers/${pubkey}/cart`,
        softCompute ? `?soft_compute=true` : ''
      );
      const cart = await query.json();
      return cart;
    } catch (err) {
      logSentryError('[Customer Actions] fetch customer cart', err);
      throw err;
    }
  }
);

export const fetchCustomerFormulasPreview = createAsyncThunk<
  {
    formulasPreviewSkincare: FormulasPreview;
    formulasPreviewHaircare: FormulasPreview;
    hairProfileUpdatedAt: ISOStringDate;
  },
  string
>('customer/fetchCustomerFormulasPreview', async (pubkey, { dispatch }) => {
  try {
    const APIClient = initApiClient(dispatch);
    const query = await APIClient.get(`/v1/backoffice/customers/${pubkey}/formulaset_preview`);
    const response = await query.json();
    let hairProfileUpdatedAt;
    if (!response?.formulaset?.haircare?.survey?.updated_at) {
      const responseHP = await APIClient.get(`/v1/backoffice/customers/${pubkey}/profile`);
      const dataHP = await responseHP.json();
      hairProfileUpdatedAt = dataHP.updated_at;
    }
    const formulasPreview = {
      formulasPreviewSkincare: response?.formulaset?.skincare,
      formulasPreviewHaircare: response?.formulaset?.haircare,
      hairProfileUpdatedAt,
    };

    return formulasPreview;
  } catch (err) {
    logSentryError('[Customer Actions] fetch customer formulas preview', err);
    throw err;
  }
});

export const fetchCustomerSelfies = createAsyncThunk<
  { imagesObjects: ImageDocs; customerPubkey: string },
  string
>('customer/fetchCustomerSelfies', async (customerPubkey, { dispatch }) => {
  try {
    const APIClient = initApiClient(dispatch);
    const docsRes = await APIClient.get(`/v1/backoffice/customers/${customerPubkey}/documents/`);
    const docs = await docsRes.json();

    const imageDocs = filter({ type: 'image' })(docs);
    const imagesObjects = await Promise.all(
      map(async imgDoc => {
        const authUrl = `/v1/backoffice/customers/${customerPubkey}/documents/${imgDoc.pubkey}/download`;
        const imageRes = await APIClient.get(authUrl);
        const imageBlob = await imageRes.blob();

        return {
          ...imgDoc,
          file: imageBlob,
          objectUrl: URL.createObjectURL(imageBlob),
        };
      }, imageDocs)
    );

    return { imagesObjects, customerPubkey };
  } catch (err) {
    logSentryError('[Customer Actions] fetch customer selfies', err);
    throw err;
  }
});

type SelfiesDocuments = { filename: string; file: Blob };

export const fetchErrorDetectionResult = createAsyncThunk<
  { errorDetectionResults: any; customerPubkey: string },
  { pubkey: string; selfiesDocuments: SelfiesDocuments[] }
>('customer/fetchErrorDetectionResult', async ({ pubkey, selfiesDocuments }, { dispatch }) => {
  try {
    const APIClient = initApiClient(dispatch);

    const fetchSurvey = async () => {
      const surveyRes = await APIClient.get(`/v1/backoffice/customers/${pubkey}/profile/`);
      return surveyRes.json();
    };

    const fetchAllInfoFrontResult = async () => {
      const selfieFront = find({ filename: 'selfie_front' })(selfiesDocuments);
      const allInfoFrontFormData = new FormData();
      allInfoFrontFormData.append('file', selfieFront.file, selfieFront.filename);

      const allInfoFrontResponse = await fetch(
        `${getEnv('REACT_APP_PROSE_CV_URL')}/all_info_front`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            // 'Content-Type': 'multipart/form-data', // 200 withouth it, 400 with it (Swagger docs are misleading)
          },
          body: allInfoFrontFormData,
        }
      );
      return allInfoFrontResponse.json();
    };

    const fetchAllInfoTopResult = async () => {
      const selfieTop = find({ filename: 'selfie_top' })(selfiesDocuments);
      const allInfoTopFormData = new FormData();
      allInfoTopFormData.append('file', selfieTop.file, selfieTop.filename);

      const allInfoTopResponse = await fetch(`${getEnv('REACT_APP_PROSE_CV_URL')}/all_info_top`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          // 'Content-Type': 'multipart/form-data', // 200 withouth it, 400 with it (Swagger docs are misleading)
        },
        body: allInfoTopFormData,
      });
      return allInfoTopResponse.json();
    };

    const payload = await Promise.all([
      fetchSurvey(),
      fetchAllInfoFrontResult(),
      fetchAllInfoTopResult(),
    ]).then(([survey, allInfoFront, allInfoTop]) => ({
      customer_survey: survey,
      all_info_front: allInfoFront,
      all_info_top: allInfoTop,
    }));

    const response = await fetch(`${getEnv('REACT_APP_PROSE_CV_URL')}/detect_consultation_errors`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const errorDetectionResults = await response.json();
    return { errorDetectionResults, customerPubkey: pubkey };
  } catch (err) {
    logSentryError('[Customer Actions] fetch customer error detection results', err);
    throw err;
  }
});
