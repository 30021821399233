export const created_at = 'created_at';
export const customer = 'customer';
export const diag_color_treatments = 'diag_color_treatments';
export const diag_color_treatments_frequency = 'diag_color_treatments_frequency';
export const diag_color_treatments_location = 'diag_color_treatments_location';
export const diag_color_treatments_type = 'diag_color_treatments_type';
export const diag_comfort = 'diag_comfort';
export const diag_concerns = 'diag_concerns';
export const diag_dandruff_level = 'diag_dandruff_level';
export const diag_dandruff_type = 'diag_dandruff_type';
export const diag_density_level = 'diag_density_level';
export const diag_extensions = 'diag_extensions';
export const diag_extensions_type = 'diag_extensions_type';
export const diag_firmness = 'diag_firmness';
export const diag_firmnesses = 'diag_firmnesses';
export const diag_gray_hair = 'diag_gray_hair';
export const diag_hair_frizz = 'diag_hair_frizz';
export const diag_hair_length = 'diag_hair_length';
export const diag_hair_porosity = 'diag_hair_porosity';
export const diag_hair_texture = 'diag_hair_texture';
export const diag_hair_thickness = 'diag_hair_thickness';
export const diag_hair_type = 'diag_hair_type';
export const diag_hairloss_genetic = 'diag_hairloss_genetic';
export const diag_hairloss_level = 'diag_hairloss_level';
export const diag_hold_level = 'diag_hold_level';
export const diag_last_shampoo = 'diag_last_shampoo';
export const diag_oil_location = 'diag_oil_location';
export const diag_other_treatments = 'diag_other_treatments';
export const diag_other_treatments_frequency = 'diag_other_treatments_frequency';
export const diag_phototype = 'diag_phototype';
export const diag_regrease_delay = 'diag_regrease_delay';
export const diag_routine_effort = 'diag_routine_effort';
export const diag_routine_products = 'diag_routine_products';
export const diag_sebum_level = 'diag_sebum_level';
export const diag_sensitivity_level = 'diag_sensitivity_level';
export const diag_shampoo_frequency = 'diag_shampoo_frequency';
export const diag_skintype_afternoon = 'diag_skintype_afternoon';
export const diag_skintype_morning = 'diag_skintype_morning';
export const diag_skintype_shower = 'diag_skintype_shower';
export const diag_split_ends = 'diag_split_ends';
export const diag_styling = 'diag_styling';
export const diag_styling_frequency = 'diag_styling_frequency';
export const diag_wrinkles = 'diag_wrinkles';
export const diag_zits_frequency = 'diag_zits_frequency';
export const diag_zits_hormonal = 'diag_zits_hormonal';
export const diag_zits_location = 'diag_zits_location';
export const formulaset = 'formulaset';
export const formulasets = 'formulasets';
export const goal_anti_brass = 'goal_anti_brass';
export const goal_curl = 'goal_curl';
export const goal_hair_growth = 'goal_hair_growth';
export const goal_hair_shedding = 'goal_hair_shedding';
export const goal_moisture = 'goal_moisture';
export const goal_shine = 'goal_shine';
export const goal_skincare = 'goal_skincare';
export const goal_straight = 'goal_straight';
export const goal_volume = 'goal_volume';
export const is_soldout_fragrance = 'is_soldout_fragrance';
export const pref_fragrance = 'pref_fragrance';
export const pref_fragrance_free = 'pref_fragrance_free';
export const pref_fragrance_free_skin = 'pref_fragrance_free_skin';
export const pref_fragrance_skin = 'pref_fragrance_skin';
export const pref_gluten_free = 'pref_gluten_free';
export const pref_hair_oil_fragrance = 'pref_hair_oil_fragrance';
export const pref_silicone = 'pref_silicone';
export const pref_texture_cleanser = 'pref_texture_cleanser';
export const pref_texture_moisturizer = 'pref_texture_moisturizer';
export const pref_vegan = 'pref_vegan';
export const profile_age_range = 'profile_age_range';
export const profile_age_year = 'profile_age_year';
export const profile_allergies = 'profile_allergies';
export const profile_background = 'profile_background';
export const profile_country = 'profile_country';
export const profile_diet_carbs = 'profile_diet_carbs';
export const profile_diet_composition = 'profile_diet_composition';
export const profile_diet_dairy = 'profile_diet_dairy';
export const profile_diet_fruit = 'profile_diet_fruit';
export const profile_diet_meat = 'profile_diet_meat';
export const profile_diet_seafood = 'profile_diet_seafood';
export const profile_diet_type = 'profile_diet_type';
export const profile_ethnicity = 'profile_ethnicity';
export const profile_health_sleep = 'profile_health_sleep';
export const profile_hormones = 'profile_hormones';
export const profile_hormones_skin = 'profile_hormones_skin';
export const profile_hydration = 'profile_hydration';
export const profile_indoor = 'profile_indoor';
export const profile_outdoor = 'profile_outdoor';
export const profile_smoke_level = 'profile_smoke_level';
export const profile_sports = 'profile_sports';
export const profile_stress_cause = 'profile_stress_cause';
export const profile_stress_level = 'profile_stress_level';
export const profile_vices = 'profile_vices';
export const profile_zipcode = 'profile_zipcode';
export const pubkey = 'pubkey';
export const salon = 'salon';
export const source = 'source';
export const status = 'status';
export const updated_at = 'updated_at';
