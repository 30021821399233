import * as ApiSlugs from './apiSurveySlugs';

const questionApiSlugs = {
  'hold-level': [ApiSlugs.diag_hold_level],
  goals: [
    ApiSlugs.goal_curl,
    ApiSlugs.goal_shine,
    ApiSlugs.goal_straight,
    ApiSlugs.goal_volume,
    ApiSlugs.goal_moisture,
    ApiSlugs.goal_anti_brass,
    ApiSlugs.goal_hair_growth,
    ApiSlugs.goal_hair_shedding,
  ],
  age: [ApiSlugs.profile_age_range],
  'dandruff-level': [ApiSlugs.diag_dandruff_level],
  density: [ApiSlugs.diag_density_level],
  hairloss_genetic: [ApiSlugs.diag_hairloss_genetic],
  gray: [ApiSlugs.diag_gray_hair],
  hairloss: [ApiSlugs.diag_hairloss_level],
  length: [ApiSlugs.diag_hair_length],
  oil: [ApiSlugs.diag_sebum_level, ApiSlugs.diag_regrease_delay], // multiple slugs
  porosity: [ApiSlugs.diag_hair_porosity],
  sensitivity: [ApiSlugs.diag_sensitivity_level],
  'shampoo-frequency': [ApiSlugs.diag_shampoo_frequency],
  'split-ends': [ApiSlugs.diag_split_ends],
  texture: [ApiSlugs.diag_hair_texture],
  thickness: [ApiSlugs.diag_hair_thickness],
  type: [ApiSlugs.diag_hair_type],
  diet: [ApiSlugs.profile_diet_composition],
  hormones: [ApiSlugs.profile_hormones],
  'smoke-exposure': [ApiSlugs.profile_smoke_level],
  exceptions: [ApiSlugs.pref_vegan, ApiSlugs.pref_silicone], // multiple slugs
  fragrance: [ApiSlugs.pref_fragrance, ApiSlugs.pref_fragrance_free], // multiple slugs
  color: [ApiSlugs.diag_color_treatments],
  'color-treatments-cadence': [ApiSlugs.diag_color_treatments_frequency],
  'color-treatments-location': [ApiSlugs.diag_color_treatments_location],
  'color-treatments-type': [ApiSlugs.diag_color_treatments_type],
  'hair-style': [ApiSlugs.diag_extensions],
  'other-treatments': [ApiSlugs.diag_other_treatments],
  'other-treatments-cadence': [ApiSlugs.diag_other_treatments_frequency],
  routine: [ApiSlugs.diag_routine_effort],
  styles: [ApiSlugs.diag_styling],
  'styles-cadence': [ApiSlugs.diag_styling_frequency],
  hydration: [ApiSlugs.profile_hydration],
  sports: [ApiSlugs.profile_sports],
  stress: [ApiSlugs.profile_stress_level],
  'stress-intensity': [ApiSlugs.profile_stress_cause],
  zipcode: [ApiSlugs.profile_zipcode, ApiSlugs.profile_country],
};

export default questionApiSlugs;
